import React from 'react';

import { Link } from '@blockworks/ui/legacy/link';

import SectionHeader from '@/components/display/headers/section-header';
import NewsletterSubscribeInput from '@/components/pages/frontpage/components/newsletter-subscribe-input';
import NewsletterPageItem from '@/components/pages/newsletter/publication/layout/newsletter-page-item';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

export type LatestPostsProps = {
    id: string | null;
    publishDate: string | null;
    thumbnailUrl: string | null;
    subject: string | null;
    linkTo: string | null;
};

export type NewsletterDailyPageLayoutProps = {
    latest?: LatestPostsProps[];
    frequency: string;
    title: string;
    description: string;
    imageUrl?: string;
    subscribeCallback: typeof subscribeToNewsletter;
};

const NewsletterPageLayout = (props: NewsletterDailyPageLayoutProps) => {
    const { latest, frequency, title, description, imageUrl, subscribeCallback } = props;

    return (
        <div className="w-full">
            <section className="mx-auto">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-10 px-10">
                    <div className="lg:px-8 max-w-4xl">
                        <div className="flex flex-col gap-5 py-10 xl:py-0 xl:-mt-24">
                            <div className="flex flex-col relative">
                                <p className="text-xl md:text-2xl text-left text-[#110b29]">{frequency}</p>
                                <p className="text-3xl md:text-5xl text-left text-[#110b29]">{title}</p>
                                <p className="text-xl md:text-2xl text-left text-[#5637cd] mt-3">{description}</p>
                            </div>
                            <div className="flex justify-start items-center gap-5 w-full">
                                <NewsletterSubscribeInput
                                    size="lg"
                                    className="w-full"
                                    inputPlaceholder="Enter email address"
                                    buttonText="Subscribe"
                                    source="newsletter-detail-page"
                                    onButtonClick={subscribeCallback}
                                />
                            </div>
                            {latest && latest[0]?.linkTo && (
                                <div>
                                    <Link
                                        href={latest[0].linkTo}
                                        className="self-stretch flex-grow-0 flex-shrink-0 w-[524px] text-lg text-left text-[#5637cd]"
                                    >
                                        View latest issue
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="hidden lg:block align-left py-10">
                        {imageUrl ? (
                            <picture>
                                <img src={imageUrl} alt="newsletter" className="h-[589px] object-cover" />
                            </picture>
                        ) : (
                            <picture>
                                <img
                                    src="/images/newsletter/newsletter-phone.png"
                                    srcSet="/images/newsletter/newsletter-phone.png 1x, /images/newsletter/newsletter-phone@2x.png 2x, /images/newsletter/newsletter-phone@3x.png 3x"
                                    className="w-[221.26px] h-[589px]"
                                    alt="newsletter"
                                />
                            </picture>
                        )}
                    </div>
                </div>
            </section>
            {latest?.length ? (
                <>
                    <SectionHeader className="w-full border-b border-t" title="Latest" linkTitle="" />
                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4">
                        {latest
                            ?.slice(0, 12)
                            .map(
                                campaign =>
                                    campaign.id &&
                                    campaign.linkTo &&
                                    campaign.publishDate && (
                                        <NewsletterPageItem
                                            key={campaign.id}
                                            id={campaign.id}
                                            time={campaign.publishDate}
                                            list={title}
                                            imageUrl={campaign.thumbnailUrl ?? ''}
                                            subject={campaign.subject ?? ''}
                                            linkTo={campaign.linkTo}
                                        />
                                    ),
                            )}
                    </section>
                </>
            ) : null}
        </div>
    );
};

export default NewsletterPageLayout;
